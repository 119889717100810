export default {
    name: 'contact',
    component() {
        return {
            selected: 1,
            init() {
                const urlParams = new URLSearchParams(window.location.search);
                const location = urlParams.get('location');
                if(location == 'knokke') {
                  this.selected = 2;
                } else if(location == 'lier') {
                  this.selected = 3;
                } else {
                  this.selected = 1;
                }
            },
        }
    }
}