export default {
    name: 'footer',
    component() {
        return {
            openNewsletterPopup: false,
            email: "",
            newsletter: {
                email: "",
                gdpr: "",
                success: false,
            },

            init() {
                if(window.innerWidth < 1000) {
                    this.openNewsletterPopup = true;
                }

                window.addEventListener("resize", () => {
                    if(window.innerWidth < 1000) {
                        this.openNewsletterPopup = true;
                    } else {
                        this.openNewsletterPopup = false;
                    }
                });

                const subscribeSuccess = new URLSearchParams(window.location.search).get('customer_posted');

                const alreadySubscribed = new URLSearchParams(window.location.search).has('contact[tags]');
                if(alreadySubscribed) {
                  this.newsletter.success = true;                  
                  this.openNewsletterPopup = true;
                }

                if(subscribeSuccess == 'true') {
                    this.openNewsletterPopup = true;
                }
            },


            validateNewsletter() {
                let emailInput = document.querySelector("[data-newsletter-email]");
                let gdprInput = document.querySelector("[data-newsletter-gdpr]");
                let valid = true;
        
                if (
                    this.newsletter.email !== "" &&
                    this.validateEmail(this.newsletter.email) && this.newsletter.gdpr == true
                ) {
                    gdprInput.style.borderColor = "";
                    emailInput.style.borderColor = "";

                } else {
                    if(this.newsletter.email == "" || this.validateEmail(this.newsletter.email) == null) {
                      emailInput.style.borderColor = "#F71C22";
                    } else {
                      emailInput.style.borderColor = "";
                    }

                    if(this.newsletter.gdpr != true) {
                        gdprInput.style.borderColor = "#F71C22";
                    } else {
                        gdprInput.style.borderColor = "";
                    }

                    valid = false;
                }
        
                if (valid) {
                    this.submitNewsletter();
                }
            },

            submitNewsletter() {
              setTimeout(() => {
                  this.newsletter.success = true;
              }, "500");

               const form = document.querySelector("form[id='contact_form']");
               form.submit();

            },

            validateEmail(email) {
              return String(email)
                  .toLowerCase()
                  .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  );
            },
        }
    }
}