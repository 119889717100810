import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";

export default {
    name: 'slider',
    component() {
      return {
        init() {
        },

        initSlider() {
            const slider = this.$root.querySelector(".swiper");
            new Swiper(slider, {
                modules: [Navigation, Autoplay, A11y],
                direction: "horizontal",
                loop: false,
                slidesPerView: 1,
                allowTouchMove: true,
                disableOnInteraction: false,
                spaceBetween: 10,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                    },
                    750: {
                        slidesPerView: 3,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-next]"),
                    prevEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-prev]"),
                },

                on: {
                    init(){
                        if(slider.closest(".slider--item").querySelectorAll("[data-prod-info]")) {
                            setTimeout(() => {
                                let height = 0;
                                let gridItems = slider.closest(".slider--item").querySelectorAll("[data-prod-info]");
                                gridItems.forEach(item => {
                                    if(item.getBoundingClientRect().height > height) {
                                        height = item.getBoundingClientRect().height;
                                    }
                                });
        
                                height = height;
    
                                slider.closest(".slider--item").querySelector(".button-next").style.bottom = `${height}px`;
                                slider.closest(".slider--item").querySelector(".button-prev").style.bottom = `${height}px`;
                            }, "100");
                        }
                    }
                }
            });
        },    

        initSliderProductImgs() {
          const slider = this.$root.querySelector(".swiper");;
          new Swiper(slider, {
              modules: [Navigation, Pagination, Autoplay, A11y],
              direction: "horizontal",
              loop: true,
              slidesPerView: 1,
              allowTouchMove: true,
              disableOnInteraction: false,
              spaceBetween: 10,
              navigation: {
                  nextEl: slider
                      .closest(".slider--item")
                      .querySelector("[data-slider-btn-next]"),
                  prevEl: slider
                      .closest(".slider--item")
                      .querySelector("[data-slider-btn-prev]"),
              },
              pagination: {
                el: slider
                    .closest(".slider--item")
                    .querySelector(".swiper-pagination"),
                type: "fraction",
            },
          });
        },

        initSliderInstagram() {
            const slider = this.$root.querySelector(".swiper");
            new Swiper(slider, {
                modules: [Navigation, Autoplay, A11y],
                direction: "horizontal",
                loop: false,
                slidesPerView: 1,
                allowTouchMove: true,
                disableOnInteraction: false,
                spaceBetween: 10,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                    },
                    750: {
                        slidesPerView: 3,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-next]"),
                    prevEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-prev]"),
                },
            });
        },    
      }
    }
  }